import React, { useEffect } from "react";
import Layout from "../../components/Layout";
import { Link } from "gatsby";
// import SEO from "../../components/SEO";
import { useState } from "react";
import { FaSpinner, FaFrown } from "react-icons/fa";

const OrderSuccess = props => {
  const [processed, setProcessed] = useState(false);
  const [error, setError] = useState(null);

  const getSesh = async () => {
    if (props.freeCheckout) {
      sendFreeFiles(props.email);
    } else {
      sendFiles();
    }
  };
  const sendFiles = async () => {
    fetch(`/.netlify/functions/get-email?sessionid=${props.sessionid}`)
      .then(res => res.json())
      .then(({ email }) => {
        const cartInfo = Object.keys(props.cartInfo).map(key => props.cartInfo[key]);
        let files = cartInfo.map(pack => {
          return {
            filename: `${pack.name}.${
              pack.loc.includes("zip")
                ? "zip"
                : pack.loc.includes("docx")
                ? "docx"
                : pack.loc.includes("doc")
                ? "doc"
                : pack.loc.includes("pptx")
                ? "pptx"
                : pack.loc.includes("ppt")
                ? "ppt"
                : pack.loc.includes("xlsx")
                ? "xlsx"
                : pack.loc.includes("xls")
                ? "xls"
                : "pdf"
            }`,
            path: `https://www.depthlearning.co.uk${pack.loc}`,
            ...(!pack.loc.includes(".zip") &&
              !pack.loc.includes(".docx") &&
              !pack.loc.includes(".doc") &&
              !pack.loc.includes(".pptx") &&
              !pack.loc.includes(".ppt") &&
              !pack.loc.includes(".xlsx") &&
              !pack.loc.includes(".xls") && { contentType: "application/pdf" }),
          };
        });
        fetch(`https://dl-cms-api.herokuapp.com/auth/local`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            identifier: process.env.GATSBY_STRAPI_USER,
            password: process.env.GATSBY_STRAPI_PASS,
          }),
        })
          .then(res => res.json())
          .then(({ jwt }) => {
            fetch(`https://dl-cms-api.herokuapp.com/sendfiles`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwt}`,
              },
              body: JSON.stringify({ files, email, cartInfo, total: props.total }),
            })
              .then(res => {
                res.json();
              })
              .then(json => {
                setProcessed(true);
              });
          });
      })
      .catch(err => {
        setError(err);
        setProcessed(true);
      });
  };

  const sendFreeFiles = async email => {
    const cartInfo = Object.keys(props.cartInfo).map(key => props.cartInfo[key]);
    let files = cartInfo.map(pack => {
      return {
        filename: `${pack.name}.${
          pack.loc.includes("zip")
            ? "zip"
            : pack.loc.includes("docx")
            ? "docx"
            : pack.loc.includes("doc")
            ? "doc"
            : pack.loc.includes("pptx")
            ? "pptx"
            : pack.loc.includes("ppt")
            ? "ppt"
            : pack.loc.includes("xlsx")
            ? "xlsx"
            : pack.loc.includes("xls")
            ? "xls"
            : "pdf"
        }`,
        path: `https://www.depthlearning.co.uk${pack.loc}`,
        ...(!pack.loc.includes(".zip") &&
          !pack.loc.includes(".docx") &&
          !pack.loc.includes(".doc") &&
          !pack.loc.includes(".pptx") &&
          !pack.loc.includes(".ppt") &&
          !pack.loc.includes(".xlsx") &&
          !pack.loc.includes(".xls") && { contentType: "application/pdf" }),
      };
    });
    fetch(`https://dl-cms-api.herokuapp.com/auth/local`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        identifier: process.env.GATSBY_STRAPI_USER,
        password: process.env.GATSBY_STRAPI_PASS,
      }),
    })
      .then(res => res.json())
      .then(({ jwt }) => {
        fetch(`https://dl-cms-api.herokuapp.com/sendfiles`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
          },
          body: JSON.stringify({ files, email, cartInfo, total: props.total }),
        })
          .then(res => {
            res.json();
          })
          .then(json => {
            setProcessed(true);
          });
      });
  };

  if (((props.cartInfo && props.total && props.sessionid) || props.freeCheckout) && !props.previouslyCalled) {
    if (!processed) {
      getSesh();
      props.setPreviouslyCalled(true);
    }
  }

  return (
    <Layout processed={processed}>
      <main className="error-page">
        <div className="error-container">
          {!processed && !error ? (
            <>
              <FaSpinner icon="spinner" className="spinner"></FaSpinner>
              <div style={{ textAlign: "center" }}>Processing your order...</div>
            </>
          ) : error ? (
            <>
              <h1>
                <div>
                  <FaFrown size={70} />
                </div>
                Sorry.
              </h1>
              <p>There was a problem processing your order. Please contact us and we will look into this for you.</p>
              <Link to="/contact" state={{ error }} className="btn">
                Contact Us
              </Link>
            </>
          ) : (
            <>
              <h1>{props.freeCheckout ? "Success" : "Payment successful"}</h1>
              <p>Thank you for your order. Your resources will be in your inbox shortly.</p>
              <Link to="/" className="btn">
                Homepage
              </Link>
            </>
          )}
        </div>
      </main>
    </Layout>
  );
};

export default OrderSuccess;
